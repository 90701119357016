<div class="full-size flex flex-col h-auto sm:h-screen">
    <div class="pt-3">

        <!-- Banner places -->
        <div class="flex justify-between flex-wrap animate__animated animate__fadeInDown animate__faster">

            <div class="flex flex-col mt-5 text-gray-700 animate__animated animate__fadeIn animate__faster" *ngIf="places.places.length > 0">
                <h1 class="font-bold text-xl">Descubrir</h1>
                <span class="text-sm">{{auth.user.email ? auth.user.email : 'Modo incógnito'}}</span>
            </div>

            <div class="flex mt-10 text-gray-600 animate__animated animate__fadeIn animate__faster" *ngIf="places.places.length == 0 && places.loading">
                <p>Cargando lugares</p>
            </div>

            <div class="flex mt-5 text-gray-600 animate__animated animate__fadeIn animate__faster" *ngIf="places.places.length == 0 && !places.loading">
                <p>No hay lugares</p>
            </div>
        </div>
        <!-- Fin banner places -->
    </div>

    <!-- Loader of places -->
    <div class="item animate__animated animate__fadeInLeft animate__faster mt-5" *ngIf="places.loading">
        <ngx-skeleton-loader animation="progress" count="7" [theme]="{
          'border-radius': '5px',
          height: '150px',
          'background-color': '#e2e8f0',
          'margin-top': '2px'
        }"></ngx-skeleton-loader>
    </div>
    <!-- Fin loader of places -->


    <!-- places -->
    <div class="overflow-y-auto scrolling-touch h-auto sm:h-full mt-5 sm:mb-3 rep">
        <!-- Reporte -->
        <div *ngFor="let place of places.places | buscar: places.buscarTerm" (click)="ver(place)" style="
                  cursor: pointer;
                background-image: linear-gradient(
                rgba(0, 0, 0, 0.7),
                rgba(0, 0, 0, 0.7)),
                 url({{place.image}});
                background-size: cover;
                background-position: center;
                color: white ;
                height: 150px;" class="h-auto w-full p-5 rounded-md mb-2 animate__animated animate__fadeIn animate__faster box">
            <div class="flex justify-between">
                <h1 class="text-white font-bold text-lg cursor-pointer">{{place.name}}</h1>
                <img src="assets/Markers/{{place.place_type}}.svg" class="h-6 mt-1 ml-2">
            </div>

            <p class="text-white text-sm truncate" style="width: 80%;">{{place.description}}</p>
        </div>
        <!-- Fin reporte -->
    </div>
    <!-- End places -->
</div>
