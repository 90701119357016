import { Component, DoCheck, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AvisameService } from '../../services/avisame.service';
import { Report } from '../../interfaces/report.interface';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Place } from '../../interfaces/place.interface';
import { types } from './consts';

@Component({
  selector: 'app-addreport',
  templateUrl: './addreport.component.html',
  styleUrls: ['./addreport.component.css']
})
export class AddreportComponent implements OnInit, OnChanges {

  icon = {
    url: 'https://cdn.worldvectorlogo.com/logos/google-maps-2020-icon.svg',
    scaledSize: {
      height: 40,
      width: 40
    }
  };

  point: { lat: number, lng: number };
  types: any;
  hora: string;
  hours = '1';
  minutes = '00';
  error = false;
  ampm: 'am' | 'pm' = 'am';
  report: Report = {
    title: '',
    type: 'homicide',
    date: new Date(),
    description: '',
    lat: 0,
    log: 0,
    nperson: '',
    country: localStorage.getItem('pais'),
    enable: true,
    time: '',
    userdi: localStorage.getItem('uid'),
    id: ''
  };

  place: Place = {
    account_type: 1,
    accessible_by_car: false,
    address: '',
    city: '',
    country: '',
    created_date: '',
    department: '',
    description: '',
    enabled: true,
    place_type: 0,
    has_delivery: false,
    latitude: undefined,
    longitude: undefined,
    lowercase_name: '',
    name: '',
    phone_number: '',
    uid: '',
    user_owner: '',
  }

  constructor(
    public reports: AvisameService,
    private router: Router,
    private toastr: ToastrService

  ) {
    this.types = types;
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('cambio');

  }

  ngOnInit(): void {
  }

  addPoint(evento) {
    this.point = evento.coords;
  }

  saveReport(form: NgForm) {

    if (this.ampm === 'pm') {
      this.hora = `${parseInt(this.hours) + 12}:${this.minutes}`;
    } else {
      this.hora = `${this.hours}:${this.minutes}`;
    }

    if (form.valid && this.point && this.hora) {

      this.report.lat = this.point.lat;
      this.report.log = this.point.lng;
      this.report.time = this.hora;
      console.log(this.report);
      this.reports.saveReport(this.report).then(() => {
        console.log('guardado');
        this.router.navigateByUrl('home');
        this.toastr.success(`${this.report.title} se ha guardado`, 'Guardado', {
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-bottom-right'
        });
      });
    } else {
      console.log('invalido');
      this.toastr.error(`Verifica que hayas ingreseado bien los datos, y que hayas seleccionado un punto en el mapa.`, 'Ups, algo anda mal', {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-right'
      });
      this.error = true;
    }


  }

  closeError(){
    this.error = false;
  }


}
