import { Component, OnInit } from '@angular/core';
import { AvisameService } from '../../services/avisame.service';
import { Place } from '../../interfaces/place.interface';
import { Router } from '@angular/router';
import { VagoService } from '../../services/vago.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  icon = {
    url: 'https://cdn.worldvectorlogo.com/logos/google-maps-2020-icon.svg',
    scaledSize: {
      height: 40,
      width: 40
    }
  };

  now = `${new Date().getDate()}/${new Date().getMonth()}/${new Date().getFullYear()}`;

  constructor(
    public places: AvisameService,
    private router: Router,
    private vago: VagoService
  ) {

  }

  ngOnInit(): void {

  }

  ver(place: Place) {
    this.router.navigate(['/view', place.uid]);
  }
}
