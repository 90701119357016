<div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
        <div>
            <img class="mx-auto h-12 w-auto" src="./assets/Images/vago_icon.svg" alt="" style="height: 75px;" alt="Workflow">
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-700">
                Registro
            </h2>

        </div>
        <form class="mt-8 space-y-6" [formGroup]="registerForm" (ngSubmit)="register()">
            <input type="hidden" name="remember" value="true">
            <div class="rounded-md shadow-sm">
                <div>
                    <label for="name" class="sr-only">Nombre</label>
                    <input id="name" name="name" type="name" autocomplete="name" formControlName="name" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                        placeholder="Nombre">
                </div>
                <div class="mt-2">
                    <label for="email-address" class="sr-only">Correo</label>
                    <input id="email-address" name="email" type="email" autocomplete="email" formControlName="email" [ngClass]="{'border-red-500': invalidEmail}" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                        placeholder="Correo">
                </div>
                <div class="mt-2">
                    <label for="password" class="sr-only">Contraseña</label>
                    <input id="password" name="password" type="password" autocomplete="current-password" [ngClass]="{'border-red-500': invalidPass}" formControlName="password" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                        placeholder="Contraseña">
                </div>

                <div class="mt-2">

                    <select name="pais" formControlName="country" class=" mr-1 block w-full text-gray-700 text-gray-900  border rounded py-3 px-2  focus:outline-none focus:bg-white focus:border-green-500 text-sm">
                        <option value="El Salvador">El Salvador</option>
                        <!-- <option value="Guatemala">Guatemala</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Nicaragua">Nicaragua</option> -->
                    </select>

                </div>
            </div>

            <div class="flex items-center justify-between">

                <div class="text-sm">
                    <a routerLink="/login" class="font-medium text-green-700 hover:text-green-600 cursor-pointer">
                        ¿Ya tienes cuenta? Inicia sesion.
                    </a>
                </div>
            </div>

            <div>
                <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                        <!-- Heroicon name: lock-closed -->
                        <svg class="h-5 w-5 text-green-200 group-hover:text-green-400" *ngIf="!onRegister"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                            aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                clip-rule="evenodd" />
                        </svg>
                        <img src="./assets/loader.svg" alt="" class="h-5 w-5 text-green-500 group-hover:text-green-400" *ngIf="onRegister" >
                    </span>
                    Registrate
                </button>
            </div>
        </form>
    </div>
</div>
