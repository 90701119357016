import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsloginGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {

  }
  canActivate(): Observable< boolean >{
    return this.authService.isAuth().pipe(
      tap( estado => {
        if (!estado) {
          this.authService.anonimousLogin();
          this.router.navigateByUrl('/login');
        }
      })
    );
  }
}
