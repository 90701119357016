import { Component, Input, OnInit, ViewChild, AfterContentInit, ElementRef } from '@angular/core';
// import Swiper from 'swiper';
import { BehaviorSubject } from 'rxjs';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Virtual,
  Autoplay,
  EffectFade,
  Parallax
} from "swiper";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, Virtual, Autoplay,EffectFade, Parallax]);

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent {

  @Input() images: BehaviorSubject<string[]>;

  constructor() { }


}
