import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  toastConfig = {
    closeButton: true,
    progressBar: true,
    positionClass: 'toast-bottom-right'
  }
  onRegister: boolean = false;

  constructor(
    private auth:AuthService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  register(){
    this.onRegister = true;
    if(this.registerForm.invalid) {
      this.toastr.warning(`Sus datos estan incompletos`, 'Upss', this.toastConfig);
      this.onRegister = false;
      return;
    }

    this.auth.crearUsuario(this.registerForm.value).then(
      () => {
      this.toastr.success(`Tu usuario ha sido creado`, 'Genial', this.toastConfig);
      this.onRegister = false;
      this.router.navigateByUrl('/terms');
      }
    ).catch(
      err => {
      this.toastr.error(err.message, 'Error', this.toastConfig);
      this.onRegister = false;
      }
    );
    
  }

  createForm() {
    this.registerForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['',[Validators.required, Validators.minLength(6)]],
      country: ['El Salvador', [Validators.required]]
    })
  }

  get invalidPass () {
    return this.registerForm.get('password').invalid && this.registerForm.get('password').touched;
  }

  get invalidEmail () {
    return this.registerForm.get('email').invalid && this.registerForm.get('email').touched;
  }

}
