<div class="w-full h-screen">
    <div class="shadow-md">
        <app-navbar></app-navbar>
    </div>
    <div class="flex flex-wrap sm:grid sm:grid-cols-2 full-size">

        <div class="rounded-lg h-auto px-4 xl:px-24 mt-8 ">
            <div class="animate__animated animate__fadeIn animate__faster">
                <h1 class="text-3xl text-gray-800 font-bold">Sugerir lugar</h1>
                <p class="text-sm text-gray-700 mb-8">Ingrese todos los detalles del lugar y seleccione un punto dando click en el mapa.</p>
            </div>

            <form class="w-full" #reporteForm="ngForm" (ngSubmit)="saveReport(reporteForm)" class="animate__animated animate__fadeInLeft animate__faster">
                <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full md:w-1/2 px-3 mb-3">
                        <input required [(ngModel)]="place.name" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                            id="name" name="name" type="text" placeholder="Nombre">
                    </div>

                    <div class="w-full md:w-1/2 px-3 mb-3">
                        <select id="place_type" name="place_type" required required [(ngModel)]="place.place_type" class="block w-full mr-1 text-gray-700 border border-gray-300 placeholder-gray-500 rounded py-2 px-1 text-gray-900 rounded-md leading-tight focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 text-sm">
            <option *ngFor="let t of types" [value]="t.place_type">{{t.name}}</option>
          </select>
                    </div>

                    <div class="w-full md:w-1/2 px-3 mb-3">
                        <input required [(ngModel)]="report.description" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm "
                            id="description" name="descripcion" type="text" placeholder="Descripción">
                    </div>

                    <div class="w-full md:w-1/2 px-3 mb-3">
                        <input name="nperson" required required [(ngModel)]="report.nperson" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                            id="nperson" type="number" min="0" placeholder="Número de personas">
                    </div>


                </div>


                <!-- Buttons -->

                <div class="flex md:items-center mb-5">
                    <div class="w-auto">
                        <button class="shadow text-white bg-green-700 hover:bg-green-600 focus:outline-none transition duration-150 ease-in-out font-semibold py-2 px-8 rounded" type="submit">
            Guardar
          </button>
                    </div>
                    <div class="w-auto ml-3">
                        <button routerLink="/home" class="border hover:text-gray-700 hover:border-gray-500  focus:shadow-outline focus:outline-none text-gray-500 font-semibold py-2 px-8 rounded" type="button">
            Cancelar
          </button>
                    </div>
                </div>
                <!-- Fin Buttons -->


            </form>

            <div class="w-full md:w-1/2  mt-3" *ngIf="point">
                <p class="text-sm text-gray-500">Lat: {{point.lat}}</p>
                <p class="text-sm text-gray-500">Lng: {{point.lng}}</p>
            </div>


        </div>

        <!-- Map -->
        <div class="w-full sm:w-auto h-full">
            <agm-map (mapClick)="addPoint( $event )" class="" [latitude]="reports.myPosition.latitude" [longitude]="reports.myPosition.longitude" *ngIf="reports.myPosition" [zoom]="15">
                <agm-marker *ngIf="point" [latitude]="point.lat" [longitude]="point.lng">
                </agm-marker>
            </agm-map>
        </div>
        <!-- Fin Map -->


    </div>
</div>
