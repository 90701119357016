export class Usuario {

    constructor (
      public uid: string,
      public email: string,
      public name?: string,
      public acount_type?: number,
      public country?: string,
      public created_date?: Date,
      public enabled?: boolean,
      public lowecase_name?: string,
       )
    {}
 }