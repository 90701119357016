import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AddreportComponent } from './pages/addreport/addreport.component';
import { TermsComponent } from './pages/terms/terms.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthGuard } from './guard/auth.guard';
import { FavoriteComponent } from './pages/favorite/favorite.component';
import { IsloginGuard } from './guard/islogin.guard';
import { PlaceComponent } from './pages/place/place.component';


const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [IsloginGuard]},
  {path: 'home', component: HomeComponent, canActivate: [IsloginGuard]},
  {path: 'add', component: AddreportComponent, canActivate:[AuthGuard, IsloginGuard]},
  {path: 'favorite', component: FavoriteComponent, canActivate:[AuthGuard, IsloginGuard]},
  {path: 'terms', component: TermsComponent},
  {path: 'login', component: LoginComponent},
  {path: 'view/:id', component: PlaceComponent, canActivate:[IsloginGuard]},
  {path: 'register', component: RegisterComponent},
  {path: '**', pathMatch: 'full', redirectTo: 'home'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
