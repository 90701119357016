<div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
        <div>
            <img class="mx-auto h-12 w-auto" src="./assets/Images/vago_icon.svg" alt="" style="height: 75px;" alt="Workflow">
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-700">
                Inicia sesión
            </h2>
            <p class="mt-2 text-center text-sm text-gray-600">
                Ó
                <a (click)="anonimLogin()" class="font-medium text-green-700 hover:text-green-600 cursor-pointer">
                    Continuar sin iniciar sesión
                </a>
            </p>
        </div>
        <form class="mt-8 space-y-6" [formGroup]="loginForm" (ngSubmit)="login()">
            <input type="hidden" name="remember" value="true">
            <div class="">
                <div class="mb-4">
                    <label for="email-address" class="sr-only">Correo</label>
                    <input id="email-address" name="email" type="email" autocomplete="email" formControlName="email" class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                        placeholder="Email address">
                </div>
                <div>
                    <label for="password" class="sr-only">Contraseña</label>
                    <input id="password" name="password" type="password" autocomplete="current-password" formControlName="password" class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                        placeholder="Password">
                </div>
            </div>

            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <input id="remember_me" name="remember_me" type="checkbox" class=" h-4 w-4 text-green-700">
                    <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                        Recuerdame
                    </label>
                </div>

                <div class="text-sm">
                    <a routerLink="/register" class="font-medium text-green-700 hover:text-green-600">
                        ¿No tienes cuenta? Registrate aquí.
                    </a>
                </div>
            </div>

            <div>
                <button type="submit" [disabled]="logging" class="disabled:opacity-50 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                        <!-- Heroicon name: lock-closed -->
                        <svg class="h-5 w-5 text-green-200 group-hover:text-green-400" *ngIf="!logging"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                            aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                clip-rule="evenodd" />
                        </svg>
                        <img src="./assets/loader.svg" alt="" class="h-5 w-5 text-green-600 group-hover:text-green-400" *ngIf="logging" >
                    </span>
                    Iniciar sesión
                </button>

            </div>
        </form>
    </div>
</div>
