import { Component, OnInit } from '@angular/core';
import { AvisameService } from '../../services/avisame.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(   
     public places: AvisameService,
     public auth: AuthService,
     public avisameService: AvisameService,
     private router: Router
    ) { }

  ngOnInit(): void {
  }

  buscar(term: string) {
    this.places.buscarTerm = term;
  }

  logout(){
    this.auth.logout().then(() => {
      this.auth.user = null;
      this.router.navigateByUrl('/login');
    });
  }

}
