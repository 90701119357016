import { Injectable } from '@angular/core';

import { ejemplo } from '../interfaces/report.interface';

@Injectable({
  providedIn: 'root'
})
export class VagoService {

  place: any = {
    description: 'this description',
    id: 1,
    name: 'Termos',
    latitude: 121212,
    longitude: 1212121,
    resizedImages: {
      md: [],
      sm: [],
      original:[]
    },
    type: 'BEACH'
  };

  exaple: ejemplo = {
    id: 1,
    type: 'Beach'
  }

  // vagoServiceInstance: VagoServiceEngine;
  unserToken = '';

  constructor(

  ) {
    // this.vagoServiceInstance = VagoServiceEngine.getVagoServiceEngine(
    //   'http://ec2-52-203-94-34.compute-1.amazonaws.com:3000/api/v1',
    //   '',
    //   '',
    //   '',
    //   '',
    //   0,
    //   this.getLoggedUser,
    //   async (key: string, value: string) => {
    //     localStorage.setItem(key, value)
    //   },
    //   'LS_USER_DATA'
    //   )

    // this.login();

  }

  // login() {
  //   this.vagoServiceInstance.authService.LOGIN('jose.jlq@hotmail.com', '65286528').then(
  //     res=>console.log(res)
  //   )

  // }

  //  async getLoggedUser() {
  //   const loggedUserString = localStorage.getItem('LS_USER_DATA');
  //   return JSON.parse(loggedUserString) as LoggedUser;
  // }
}
