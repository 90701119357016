<div class="w-full h-screen">
    <div class="shadow-md">
        <app-navbar></app-navbar>
    </div>
    <div class="flex flex-wrap px-3 sm:px-0 sm:grid sm:grid-cols-4 full-size">

        <div class="w-full sm:w-auto sm:col-span-2 lg:col-span-1 h-auto sm:mx-5">
            <app-sidebar></app-sidebar>
        </div>

        <!-- Map -->
        <div class="w-full sm:w-auto sm:col-span-2 lg:col-span-3 h-auto animate__animated animate__fadeInRight animate__faster">
            <h1 class="text-3xl font-bold text-gray-700 sm:hidden">Mapa</h1>
            <!-- Map Instance -->
            <agm-map [latitude]="places.myPosition.latitude" [longitude]="places.myPosition.longitude" *ngIf="places.myPosition" [zoom]="10">

                <!-- My position marker -->
                <agm-marker [latitude]="places.myPosition.latitude" [longitude]="places.myPosition.longitude" [iconUrl]="icon">
                    <agm-info-window>
                        <div class="p-3 animate__animated animate__fadeIn animate__faster">

                            <strong class="text-lg font-bold text-gray-700">Tu Ubicación</strong>
                            <p class="text-gray-700">Te encuentras aquí</p>
                        </div>
                    </agm-info-window>
                </agm-marker>
                <!-- My position marker -->



                <!-- places markers -->
                <agm-marker *ngFor="let point of places.places | buscar: places.buscarTerm" [latitude]="point.latitude" [longitude]="point.longitude" [iconUrl]="point.icon">
                    <agm-info-window>
                        <div class="p-3 animate__animated animate__fadeIn animate__faster">
                            <div class="flex justify-between">
                                <h1 class="text-gray-700 font-bold text-lg cursor-pointer mb-2" (click)="ver(point)">{{point.name}}</h1>
                            </div>

                            <p class=" text-gray-600 text-sm mt-1 mb-2">{{point.description}}</p>
                            <a class="text-green-600 text-sm cursor-pointer" style="text-decoration: underline #38a169;" (click)="ver(point) ">Ver</a>
                        </div>
                    </agm-info-window>

                </agm-marker>
                <!--Fin places markers -->
            </agm-map>
            <!-- Fin  Map Instance -->
        </div>
        <!-- Fin Map -->

    </div>
</div>