import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http'

import { SwiperModule } from 'swiper/angular';
import { RatingModule } from 'ng-starrating';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';

// Angula Google Mapas
import { AgmCoreModule } from '@agm/core';

// Pages
import { HomeComponent } from './pages/home/home.component';
import { AddreportComponent } from './pages/addreport/addreport.component';
import { TermsComponent } from './pages/terms/terms.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AboutComponent } from './pages/about/about.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BuscarPipe } from './pipes/buscar.pipe';
import { AuthModule } from './auth/auth.module';
import { FavoriteComponent } from './pages/favorite/favorite.component';
import { SliderComponent } from './shared/slider/slider.component';
import { FavoriteGridComponent } from './shared/favorite-grid/favorite-grid.component';
import { PlaceComponent } from './pages/place/place.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AddreportComponent,
    TermsComponent,
    AboutComponent,
    NavbarComponent,
    SidebarComponent,
    BuscarPipe,
    FavoriteComponent,
    SliderComponent,
    FavoriteGridComponent,
    PlaceComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBHjoimmcK1YsCkcO_dYVnoMyaUch7xggY'
    }),
    ToastrModule.forRoot(), // ToastrModule added
    BrowserAnimationsModule, // required animations module
    NgxSkeletonLoaderModule.forRoot(),
    AuthModule,
    SwiperModule,
    RatingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
