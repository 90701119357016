import { Component, OnInit } from '@angular/core';
import { AvisameService } from '../../services/avisame.service';
import { AuthService } from '../../services/auth.service';
import { Place } from '../../interfaces/place.interface';
import { Router } from '@angular/router';
declare let alertify: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  public about = false;

  constructor(
    public places: AvisameService,
    public auth: AuthService,
    private router: Router

  ) {
    if (localStorage.getItem('about')){
      this.about = true;
      localStorage.removeItem('about');
    }
   }

  ngOnInit(): void {
  }

  
  changeAbout() {
    this.about = !this.about;
  }

  alert(place: Place) {
    alertify.alert(place.name, `<app-slider></app-slider>`).setting({
      'modal': true,
      'maximizable': true,
      'resizable': true,
      'overflow':true
    }).resizeTo('80%','80%');
  }

  ver(place: Place) {
    this.router.navigate(['/view', place.uid]);
  }

}
