import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AvisameService } from '../../services/avisame.service';
import { Place } from '../../interfaces/place.interface';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { StarRatingComponent } from 'ng-starrating';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.css']
})
export class PlaceComponent implements OnInit, OnDestroy {

  placeId: string;
  rating = 0;
  myRaiting = 0;
  place: Place;
  isFav: boolean;
  weather: any;
  opinion: string = '';
  opinions : BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  images : BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  toastConfig = {
    closeButton: true,
    progressBar: true,
    positionClass: 'toast-bottom-right'
  }

  constructor(
    private ar: ActivatedRoute,
    private placeService: AvisameService,
    private toastr: ToastrService,
    public authService: AuthService,
    private router: Router
  ) {
    this.ar.params.subscribe((uid: any) => this.placeId = uid.id);
    localStorage.setItem('terms', 'true');
  }
  ngOnDestroy(): void {
    this.placeService.hideSearch = false;
  }

  ngOnInit(): void {
    this.placeService.hideSearch = true;
    this.placeService.getPlace(this.placeId).subscribe(place => {
      this.place = place;
      this.images.next(place.images);
      this.isFavorito();
      this.getRate();
      this.getWeater();
      this.getMyRate();
      this.getOpinions();
    })
  }

  goMaps() {
    window.open(`https://www.google.com/maps/@${this.place.latitude},${this.place.longitude},18z`, '_blank');
  }

  isFavorito() {
    this.placeService.isFavorito(this.placeId).subscribe(
      fav => {
        fav ? this.isFav = true : this.isFav = false;
      }
    );
  }

  setFavorito() {
    if (this.authService.user.isAnonymous) {
      this.router.navigateByUrl('/register');
      this.toastr.error('Debes registrarte 😥', 'Error', this.toastConfig);
      return false;
    }

    if (this.isFav) {
      //delete
      this.placeService.deleteFavorito(this.placeId).then(
        res => {
          this.toastr.warning(`${this.place.name}`, 'Ya no es favorito 😐', this.toastConfig);
        }
      )
    } else {
      //add
      this.placeService.addFavorio(this.placeId).then(
        res => {
          this.toastr.success(`${this.place.name}`, 'Se agregó a favoritos 🤩', this.toastConfig);

        }
      )
    }
  }

  getWeater() {
    this.placeService.getWeather(this.place).subscribe( res => this.weather = res)
  }

  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {

    if (this.authService.user.isAnonymous) {
      this.router.navigateByUrl('/register');
      this.toastr.error('Debes registrarte 😥', 'Error', this.toastConfig);
      return false;
    }
    // this.myRaiting = $event.newValue;
    this.placeService.rating(this.placeId, $event.newValue).then(
      res => {
        this.toastr.success(`${$event.newValue} ⭐`,'Haz calificado', this.toastConfig);
        this.getRate();
      }
    )
  }

  getMyRate() {
    this.placeService.getMyRating(this.placeId).subscribe((rating: any) => rating ? this.myRaiting = rating.rating: 0);
  }

  getRate() {
    this.rating = 0;
    this.placeService.getRating(this.placeId).subscribe((ratings: any[]) => {
      ratings.forEach(rat => {
        this.rating += rat.rating;
      })
      this.rating /= ratings.length;
    });
  }

  getOpinions() {
    this.placeService.getOpinions(this.placeId).subscribe((opinions: any) => this.opinions.next(opinions))
  }

  addOpinion() {
    if (this.opinion.length > 0) {
    this.placeService.doOpinion(this.placeId, this.opinion).then(
      res=> {
        this.toastr.success(`${this.opinion}`,'Haz dejado tu opinión 👌', this.toastConfig);
        this.opinion = '';
        const opDiv = document.getElementById('ops');
        if(opDiv) opDiv.scrollIntoView(true);
      }
    );

    }
  }

}
