<div class="px-5 sm:px-20 py-10 animate__animated animate__fadeInLeft animate__faster">
  <!-- Terms -->
  <div class="bg-white overflow-hidden sm:rounded-lg border-b border-gray-300">
    <div class="px-4 py-5 border-b border-gray-200">
      <h3 class="text-3xl font-bold text-gray-700 leading-6 font-medium text-gray-900">
        Términos  y condiciones
      </h3>
      <p class="mt-1 max-w-2xl text-sm leading-8 text-gray-500">
        Debes aceptar los términos y condiciones para poder usar esta aplicación
      </p>
    </div>
    <div>
      <dl>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Permiso de ubicación
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            La aplicación necesita permisos de ubicación para mostrar informes y alertas cerca de usted, la aplicación NO funcionará sin estos permisos, por favor proporcionelos.
          </dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Veracidad de la información
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            Solo puedes reportar eventos reales, cualquier reporte falso resultará en que su cuenta sea cerrada. No nos hacemos responsables del mal uso de la aplicación o de algún tipo de problema social.
          </dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Política de Privacidad
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
            La aplicación recolecta datos de ubicación con el fin de crear una experiencia de usuario real, ya que éste puede crear reportes de hechos delictivos y solo esta permitido que lo pueda hacer cerca del lugar de los hechos, ademas también recolecta información del dispositivo para combatir reportes falsos ya que si un usuario envía un reporte falso, este se desechara, la aplicación también solicita permisos de acceso a internet para comunicarse con los servidores y funcionar correctamente, ademas de permisos de llamadas para el funcionamiento del botón de llamada de emergencia dentro de la aplicación.
          </dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm leading-5 font-medium text-gray-500">
            Contribuye reportando activamente
          </dt>
          <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 mb-5">
            Contribuye y ayuda a denunciar cualquier acto criminal que presencie o vea en las noticias, para que todos estemos informados.
          </dd>
        </div>
      </dl>
    </div>
  </div>
  <!-- Fin Terms -->


  <!-- Buttons -->
  <div class="flex justify-between w-auto mt-5">
    <div class="md:flex mb-6">
      <label class="block text-gray-500 font-bold">
        <input class="mr-2 leading-tight bg-green-500" type="checkbox" name="terms" [(ngModel)]="terms">
        <span class="text-sm">
          Acepto términos y condiciones
        </span>
      </label>
    </div>

    <div class="sm:flex sm:items-right">
      <div class="sm:w-2/3">
        <button (click)="aceptarTerminos()"
          class="shadow bg-green-500 hover:bg-green-600 focus:shadow-outline focus:outline-none text-white py-2 px-4 rounded"
          type="button">
          Guardar
        </button>
      </div>
    </div>
  </div>
  <!-- Fin Buttons -->


</div>
