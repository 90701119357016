export class Point {

  constructor (
    public latitude: number,
    public longitude: number,
    public titulo: string = 'Sin título',
    public descripcion: string = 'Sin descripción'

      )
   {}
}
