export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyArdoIvQ86sHBnMJUO8LLAz0YkrIC7ItC8',
    authDomain: 'vago-c77fa.firebaseapp.com',
    projectId: 'vago-c77fa',
    storageBucket: 'vago-c77fa.appspot.com',
    messagingSenderId: '1013847332687',
    appId: '1:1013847332687:web:9c12b3079b978dbf01d73a',
    measurementId: 'G-6ZM0G1PTS'
  },
  weather_key:'2d8f582dca91d7eff7974374a56025ca'
};
