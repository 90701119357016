// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyArdoIvQ86sHBnMJUO8LLAz0YkrIC7ItC8',
    authDomain: 'vago-c77fa.firebaseapp.com',
    projectId: 'vago-c77fa',
    storageBucket: 'vago-c77fa.appspot.com',
    messagingSenderId: '1013847332687',
    appId: '1:1013847332687:web:9c12b3079b978dbf01d73a',
    measurementId: 'G-6ZM0G1PTS'
  },
  weather_key:'2d8f582dca91d7eff7974374a56025ca'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
