import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buscar'
})
export class BuscarPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const buscarPorNombre = [];
    for ( const place of value) {
      if (place.name.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
        buscarPorNombre.push(place);
      }
    }
    return buscarPorNombre;
  }

}
