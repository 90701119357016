<div class="w-full h-screen px-0 xl:px-24">
    <h1 class="text-3xl text-gray-800 font-bold mt-8 mx-4 my-5" *ngIf="favoritos?.length > 0">Tus lugares favoritos</h1>
    <div class="flex flex-col flex-wrap sm:flex-row mx-4 my-5" *ngIf="favoritos?.length > 0">
        <div class="w-full my-2 lg:my-2 lg:px-2 lg:w-1/5 md:w-1/3 md:px-1 animate__animated animate__fadeIn cursor-pointer" *ngFor="let place of favoritos | buscar: places?.buscarTerm">

            <!-- Article -->
            <article class=" rounded-lg box" style="border: 1px solid #e2e8f0;">

                <div class="block h-auto w-full" style="height: 200px;" (click)="ver(place)">
                    <img loading="lazy" class="object-cover h-48 w-full" [src]="place.images[0]" style="border-top-left-radius: 5px; border-top-right-radius: 5px;">
                </div>


                <header class="flex leading-tight px-4 pt-2 md:px-4 md:pb-0 md:pt-1">
                    <h1 class="text-sm leading-5 font-medium text-gray-700 truncate">
                        {{place.name}}
                    </h1>
                </header>

                <footer class="flex justify-between px-4 pb-2 md:px-4 md:pt-0 md:pb-2">

                    <p class="text-gray-500 text-sm">
                        {{place.created_date}}
                    </p>

                    <div class="flex">

                        <!-- <a  title="Ver" class="text-gray-700 mr-2">
                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                    </svg>
                  </a>

                  <a target="_blank" title="Compartir" class="cursor-pointer text-gray-700 mr-3"
                    >
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                    </svg>
                  </a> -->

                        <a title="Borrar" class="cursor-pointer text-gray-700" (click)="borrar(place)">

                            <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                        </a>
                    </div>

                </footer>

            </article>
        </div>
    </div>

    <div class="animate__animated animate__fadeIn animate__faster mx-4" *ngIf="favoritos?.length == 0">
        <h1 class="text-gray-700 text-xl font-bold mt-8">Aún no agregas favoritos</h1>
        <p class="text-sm text-gray-700 mb-3">Para agregar un favorito, solo dale click al corazón</p>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-700" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd"
        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
        clip-rule="evenodd" />
    </svg>
    </div>
</div>
