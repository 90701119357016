export const types = [
    { "name": "Sitio Arqueológico", "place_type": 0 },
    { "name": "Playa / Costa", "place_type": 1 },
    { "name": "Cabaña", "place_type": 2 },
    { "name": "Cerro / Montaña", "place_type": 3 },
    { "name": "Lago", "place_type": 4 },
    { "name": "Mirador", "place_type": 5},
    { "name": "Museo", "place_type": 6 },
    { "name": "Parque", "place_type": 7 },
    { "name": "Piscinas", "place_type": 8 },
    { "name": "Río", "place_type": 9 },
    { "name": "Sitio Turistico", "place_type": 10 },
    { "name": "Cascadas", "place_type": 11 },
    { "name": "Zoológico", "place_type": 12},
  ];