import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';

import { Report } from '../interfaces/report.interface';
import { filter, map, take, tap } from 'rxjs/operators';
import { Point } from '../models/point.model';
import { Router } from '@angular/router';
import { Place } from '../interfaces/place.interface';
import { AuthService } from './auth.service';
import { async } from '@angular/core/testing';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AvisameService {


  public notice: Report;
  public places: Place[] = [];
  public favoritos: Place[];
  public myPosition: Point;
  public loading = true;
  public buscarTerm = '';
  public placeVer: Place;
  public hideSearch = false;


  constructor(
    private af: AngularFirestore,
    private storage: AngularFireStorage,
    private auth: AuthService,
    private http: HttpClient
  ) {
    this.getMyPosition();
    // if (!localStorage.getItem('terms')) {
    //   this.router.navigateByUrl('/terms');
    // }
    this.getPlaces();

    if(!this.auth.user.isAnonymous) {
      this.getFavoritosDb();
    }
  }

  getPlaces() {
    this.loading = true;
    // Filtro de fechas de los reportes de los ultimos 3 dias
    this.af.collection('places', ref => ref.where('enabled', '==', true)).valueChanges().pipe(
      map((places: Place[]) => {
        for (let p of places) {
          p.created_date = `${new Date(p.created_date.seconds * 1000).getDate()}/${new Date(p.created_date.seconds * 1000).getMonth()}/${new Date(p.created_date.seconds * 1000).getFullYear()}`;
          p.icon = {
            url: `assets/Markers/${p.place_type}.svg`,
            scaledSize: {
              height: 35,
              width: 35
            }
          };

          // Agregando las imagenes a cada lugar
          const ref = this.storage.ref(`Images/${p.uid}`);
          ref.listAll().subscribe(files => files.items[0].getDownloadURL().then(url => p.image = url), error => console.log(error));
          // ref.listAll().subscribe(files => files.items.forEach(element => element.getDownloadURL().then(url => p.images.push(url))));
        }
        return places;
      })
    ).subscribe((places: Place[]) => {
      this.places = places;
      // console.log('lugares', this.places);

      this.loading = false;
    }
    );
  }

  getPlace(placeId: string) {
    return this.af.collection('places').doc(placeId).valueChanges().pipe(
      map((place: Place) => {

        place.created_date = `${new Date(place.created_date.seconds * 1000).getDate()}/${new Date(place.created_date.seconds * 1000).getMonth()}/${new Date(place.created_date.seconds * 1000).getFullYear()}`;
        place.icon = {
          url: `assets/Icons/marker_${place.place_type}.svg`,
          scaledSize: {
            height: 35,
            width: 35
          }
        };
        place.images = [];
        const ref = this.storage.ref(`Images/${place.uid}`);
        ref.listAll().subscribe(files => files.items.forEach(element => element.getDownloadURL().then(url => place.images.push(url))), error => console.log(error));
        return place;
      })
    );
  }


  getMyPosition() {
    console.log('Get pos');

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.myPosition = new Point(position.coords.latitude, position.coords.longitude);
      },
      err => { if (err.code == 1) this.getPosition();}
      );
    } else {
      console.log('No soportado');
    }
  }

  getPosition() {
    this.http.get('https://geolocation-db.com/json/').subscribe((data: any)=> {
      this.myPosition = new Point(data?.latitude, data.longitude);
    })
  }

  saveReport(report: Report) {
    return this.af.collection('reports').add(report);
  }

  getFavoritosDb() {
    this.af.collection(`user-places-favorites/${this.auth.user.uid}/places`).valueChanges().subscribe(
      (fav: any[]) => {
        this.favoritos = [];
        for (let f of fav) {
          this.getPlace(f.id).subscribe(place => this.favoritos.push(place));
        }
      }
    );
  }

  getFavoritos(): Place[] {
    return this.favoritos?.length > 0 ? this.favoritos : [];
  }

  isFavorito(uid: string) {
    return this.af.doc(`user-places-favorites/${this.auth.user.uid}/places/${uid}`).valueChanges();
  }

  getRating(id: string) {
    return this.af.collection(`place-ratings/${id}/ratings`).valueChanges();
  }

  addFavorio(id: string) {
    return this.af.doc(`user-places-favorites/${this.auth.user.uid}/places/${id}`).set({id: id});
  }

  deleteFavorito(id) {
    return this.af.doc(`user-places-favorites/${this.auth.user.uid}/places/${id}`).delete();
  }

  getWeather(place: Place) {
    let params = new HttpParams()
    .append('lat', place.latitude.toString())
    .append('lon', place.longitude.toString())
    .append('appid', environment.weather_key)
    .append('units','metric')
    .append('lang', 'es');

    return this.http.get('http://api.openweathermap.org/data/2.5/weather', {params});
  }

  getMyRating(uid: string) {
    return this.af.doc(`place-ratings/${uid}/ratings/${this.auth.user.uid}`).valueChanges();
  }

  rating(uid: string, rating: number){
    return this.af.doc(`place-ratings/${uid}/ratings/${this.auth.user.uid}`).set({'rating': rating});
  }

  getOpinions(uid: string) {
    return this.af.collection(`place-opinions/${uid}/opinions`, ref => ref.orderBy('date_timestamp', 'desc')).valueChanges();
  }

  doOpinion(uid: string, opinion: string) {
    return this.af.collection(`place-opinions/${uid}/opinions`).add({
      date: this.getStringDate(),
      date_timestamp: new Date(),
      from_user_name: this.auth.userLogin.name,
      from_user_uid: this.auth.user.uid,
      text: opinion,
      time: new Date().getHours().toString()
    })
  }


  getStringDate() {
    const date = new Date();
    return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
  }

}

