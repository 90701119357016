<div class="flex flex-col justify-between h-full ">
  <div class="pt-3 flex flex-wrap">
    <img src="./assets/Images/vago_icon.svg" class="h-20 mr-3 mt-1">
  </div>
  <div class="h-auto animate__animated animate__fadeInLeft animate__faster">
    <p class="mt-10 text-sm text-gray-600">
      Avisame App es una aplicación móvil para reportar actos delictivos en la región, con la posibilidad de verlos en
      tiempo real sobre un mapa, además características como llamadas de emergencia y notificaciones instantaneas
    </p>

    <div class="mt-5">
      <p class="text-gray-700 font-semibold">Developers</p>
      <p class="text-sm text-gray-600 mt-2">Móvil</p>
      <p class="text-sm text-gray-600">Luis Quijada - jose.jlq@hotmail.com</p>
      <p class="text-sm text-gray-600 mt-2">Web</p>
      <p class="text-sm text-gray-600">Alejandro Alas - alejandroalas7536@gmail.com</p>
      <p class="text-gray-700 font-semibold mt-5">Main idea</p>
      <p class="text-sm text-gray-600">Luis Quijada - jose.jlq@hotmail.com</p>
      <div class="mt-12 flex justify-center">
        <img src="assets/ele.jpeg" alt="" class="h-8">
      </div>

    </div>

  </div>
</div>
