import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators'
import { Usuario } from '../models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: { email: string, isAnonymous: boolean, uid: string };
  public userLogin!: any;

  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore
  ) { }

  initAuthListener() {
    this.auth.authState.subscribe(fuser => {
      if (fuser) {
        this.user = fuser;
        this.getUser();
      }
    },
      error => {
        console.error(error);

      }
    )
  }

  crearUsuario(userForm: any) {
    return this.auth.createUserWithEmailAndPassword(userForm.email, userForm.password).then(
      ({ user }) => {

        const { name, email, country } = userForm;
        const lowercaseName: string = name;
        lowercaseName.toLocaleLowerCase();

        const newUser = new Usuario(user.uid, email, name, 0, country, new Date(), true, lowercaseName);
        return this.firestore.collection('users').doc(user.uid).set({ ...newUser });
      }
    );
  }

  login(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.auth.signOut().catch(console.log);
  }

  isAuth() {
    return this.auth.authState.pipe(
      map(fuser => fuser != null)
    );
  }

  anonimousLogin() {
    return this.auth.signInAnonymously();
  }

  getUser() {
    this.firestore.doc(`users/${this.user.uid}`).valueChanges().subscribe(user => this.userLogin = user);
  }


}
