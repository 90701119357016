import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  toastConfig = {
    closeButton: true,
    progressBar: true,
    positionClass: 'toast-bottom-right'
  }

  logging: boolean = false;

  constructor(
    private auth:AuthService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService

  ) {
    if ( this.isMobile() ) {
      console.log('Cell');
      // window.location.href = 'https://play.google.com/store/apps/details?id=com.elesteam.vago&hl=es&gl=US';
     }
   }

  ngOnInit(): void {
    this.createForm();
  }

  login() {
    this.logging = true;
    if(this.loginForm.invalid) {
      this.toastr.warning(`Debe ingresar su email y password`, 'Upss', this.toastConfig);
      this.logging = false;
      return;
    }
    const {email, password} = this.loginForm.value;
    this.auth.login(email, password).then(
      (user:any) => {
        this.router.navigateByUrl('/home')
        this.toastr.success(`${user.user.email}`, 'Bienvenido', this.toastConfig);
        this.logging = false;

      }
    ).catch(
      err => {
        this.toastr.error(`${err}`, 'Error', this.toastConfig);
        this.logging = false;
      }
    );

  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    })
  }

  anonimLogin() {
    this.auth.anonimousLogin().then(
      () => {
        this.router.navigateByUrl('/home')
        this.toastr.success('Haz iniciado en modo incógnito', 'Bienvenido', this.toastConfig);
      }
    )
  }

  isMobile() {
    return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );
}

}
