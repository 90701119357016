import { Component, OnInit } from '@angular/core';
import { AvisameService } from '../../services/avisame.service';
import { AuthService } from '../../services/auth.service';
import { Place } from '../../interfaces/place.interface';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-favorite-grid',
  templateUrl: './favorite-grid.component.html',
  styleUrls: ['./favorite-grid.component.css']
})
export class FavoriteGridComponent implements OnInit {

  public favoritos: Place[];
  toastConfig = {
    closeButton: true,
    progressBar: true,
    positionClass: 'toast-bottom-right'
  }

  constructor(
    public places: AvisameService,
    public auth: AuthService,
    private router: Router,
    private toastr: ToastrService

  ) {
  }

  ngOnInit(): void {
    this.getFavoritos();
    this.places.buscarTerm = '';
  }

  borrar(place: Place) {
    this.places.deleteFavorito(place.uid).then(
      res => {
        this.getFavoritos();
        this.toastr.warning(`${place.name}`, 'Ya no es favorito 😐', this.toastConfig);
      }
    )
  }

  compartir() {

  }

  cancelar() {

  }

  ver(place: Place) {
    this.router.navigate(['/view', place.uid]);
  }

  getFavoritos() {
    this.favoritos = this.places.getFavoritos();
  }


}
