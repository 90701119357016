<div class="shadow-md">
    <app-navbar></app-navbar>
</div>
<div class="w-full h-screen px-0 xl:px-24" *ngIf="place">
    <div class="flex justify-between mt-8 mb-4 pb-2 md:pt-0 md:pb-2 mx-4">
        <div class="flex flex-col">
            <h1 class="text-3xl text-gray-800 font-bold">{{place.name}}</h1>
            <div class="flex items-center">
                <star-rating [value]="rating" totalstars="5" checkedcolor="#EDCF5D" uncheckedcolor="#EFEFEF" size="20px" readonly="false"></star-rating>
                <span class="text-sm text-gray-700 mx-2">{{rating | number: '1.0-1' }}</span>
            </div>
        </div>

        <div class="flex items-center">

            <a title="Ver" class="text-gray-700 mr-2 cursor-pointer" (click)="goMaps()" target="_blank">
                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                </svg>
            </a>

            <a target="_blank" title="Compartir" class="cursor-pointer text-gray-700 mr-3">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                </svg>
            </a>

            <a title="favorito" class="cursor-pointer mr-3" [ngClass]="{'text-green-700': isFav, 'text-gray-700': !isFav}" (click)="setFavorito()">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                    clip-rule="evenodd" />
                </svg>
            </a>

            <!-- <img src="assets/Markers/{{place.place_type}}.svg" class="h-5 w-5"> -->
        </div>

    </div>


    <div class="flex mb-8">
        <div class="w-full md:w-1/2">
            <app-slider [images]="images"></app-slider>
        </div>

        <!-- Mapa -->
        <div class="w-full md:w-1/2 animate__animated animate__fadeIn animate__faster mr-4">
            <!-- <h1 class="text-xl text-gray-800 font-bold">Mapa</h1> -->
            <div class="mr-0" style="height: 400px;">

                <!-- Map Instance -->
                <agm-map [latitude]="place.latitude" [longitude]="place.longitude" *ngIf="place" [zoom]="16" style="border-radius: 8px;">

                    <!-- My position marker -->
                    <agm-marker [latitude]="place.latitude" [longitude]="place.longitude" [iconUrl]="icon">
                        <agm-info-window>
                            <div class="p-3 animate__animated animate__fadeIn animate__faster">
                                <strong class="text-lg font-bold text-gray-700">{{place.name}}</strong>
                            </div>
                        </agm-info-window>
                    </agm-marker>
                    <!-- My position marker -->

                </agm-map>

            </div>
        </div>

    </div>

    <div class="flex flex-row justify-between flex-wrap md:pt-0 mx-4 md:pb-2">

        <div class="w-full md:w-4/6 bg-white flex flex-col animate-fade-left">
            <div class="mr-12">
                <h1 class="text-2xl text-gray-800 font-bold mb-4">Detalles</h1>
                <div id="details" class="w-full gap-8">
                    <div>
                        <div class="border-t border-b border-gray-200">
                            <dl class="divide-y divide-gray-200">
                                <div class="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0" *ngIf="place.address">
                                    <dt class="font-bold text-gray-700">Dirección</dt>
                                    <dd class="mt-1 sm:col-span-4 sm:mt-0 text-gray-700">{{place.address}}</dd>
                                </div>
                                <div class="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0" *ngIf="place.phone_number">
                                    <dt class="font-bold text-gray-700">Teléfono</dt>
                                    <dd class="mt-1 sm:col-span-4 sm:mt-0 text-gray-700">{{place.phone_number}}</dd>
                                </div>
                                <div class="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0" *ngIf="place.phone_number">
                                    <dt class="font-bold text-gray-700">Accesibilidad</dt>
                                    <dd class="mt-1 sm:col-span-4 sm:mt-0 ">
                                        <div class="" [ngClass]="{'text-green-700': place.accessible_by_car , 'text-gray-700': !place.accessible_by_car}" *ngIf="place.accessible_by_car">
                                            <i class="fas fa-car  h-5 w-5"></i>
                                            <span>{{place.accessible_by_car ? 'Accesible en vehículo' : 'No Accesible en vehículo' }}</span>
                                        </div>
                                    </dd>
                                </div>
                                <div class="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0" *ngIf="place.description">
                                    <dt class="font-bold text-gray-700">Descripción</dt>
                                    <dd class="mt-1 sm:col-span-4 sm:mt-0 text-gray-700">{{place.description}}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="w-full md:w-2/6 animate__animated animate__fadeIn animate__faster">
            <div *ngIf="weather" class="bg-green-700 p-4 rounded-lg  grid grid-cols-2 gap-4 md:w-auto md:my-0 my-4">
                <div>
                    <img src="./assets/Images/weather/{{weather.weather[0].main}}.svg" class="h-6 my-2">
                    <p class="text-white text-xl font-normal">{{weather.weather[0].description | titlecase}}</p>
                    <p class="text-white font-light text-xs">{{weather.name | titlecase}}</p>
                </div>
                <div>
                    <div class="text-right">
                        <h2 class="text-white text-4xl font-semibold text-right">{{weather.main.temp | number:'.0-1'}}º</h2>
                        <p class="text-white font-light text-xs text-right">Se siente {{weather.main.feels_like | number:'.0-1'}}º
                        </p>
                    </div>
                </div>
            </div>

            <div class=" p-4 rounded-lg  md:w-auto my-4 animate__animated animate__fadeIn animate__faster" style="border: 1px solid #e2e8f0;">
                <p class="text-gray-700 font-bold">Tu calificación</p>
                <p class="my-1 max-w-2xl text-gray-700 text-sm">Comparte la calificación que le das a este lugar a los demas visitantes.</p>
                <star-rating [value]="myRaiting" [totalstars]="5" checkedcolor="#EDCF5D" uncheckedcolor="#EFEFEF" size="20px" readonly="false" (rate)="onRate($event)"></star-rating>
                <p class="text-gray-700 font-bold mt-4">Tu opinión</p>
                <p class="my-1 max-w-2xl text-gray-700 text-sm">Comparte tus comentaros o recomendaciones de este lugar a los demas visitantes.</p>
                <div class="mt-3" *ngIf="!authService.user.isAnonymous">
                    <div class="flex flex-wrap">
                        <div class="relative text-gray-600 w-full">
                            <input type="search" name="opinion" placeholder="Escribe tu opinión" [(ngModel)]="opinion" (keyup.enter)="addOpinion()" class="bg-white h-10 px-5 pr-10 rounded-md text-sm focus:outline-none border w-full">
                            <!-- <button type="submit" class="absolute right-0 top-0 mt-2 mr-4 focus:outline-none text-green-700">
                            <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                              (click)="addOpinion()" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                            </svg>
                          </button> -->
                        </div>
                    </div>
                    <div class="mt-2 flex justify-end ">
                        <button (click)="addOpinion()" href="# " class="shadow text-white bg-green-700 hover:bg-green-600 focus:outline-none transition duration-150 ease-in-out font-semibold py-2 px-8 rounded-lg">Enviar</button>
                    </div>
                </div>
            </div>
        </div>



    </div>


    <div class="flex flex-row justify-between flex-wrap my-8 pb-2 md:pt-0 mx-4 md:pb-2  mb-8" *ngIf="opinions.value.length > 0">
        <div class="w-full md:w-4/6">
            <h1 class="text-2xl text-gray-800 font-bold mb-4">Opiniones</h1>

            <div class="mr-0  rounded-lg  flex flex-col justify-between ">
                <!-- List Opiniones -->
                <div class="scrolling-touch rep mr-12" id="ops">
                    <div *ngFor="let op of opinions | async " class="mb-2 review-container" style="border-top: 1px solid #e2e8f0;">

                        <div class="flex cursor-pointer ">
                            <div class="flex justify-start w-12 ">
                                <div>
                                    <img class="w-auto rounded-full shadow-sm" src="assets/Images/profile.png" alt="user photo">
                                </div>
                            </div>
                            <div class="flex flex-col ml-4 w-full">
                                <div class="flex justify-between ">
                                    <div class="flex flex-col ">
                                        <span class="font-bold text-green-700">{{op.from_user_name}}</span>
                                        <span style="font-size: 10px;" class="text-gray-600">{{op.date}}</span>
                                    </div>
                                </div>
                                <div>
                                    <p class="text-sm text-gray-700">{{op.text}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!--
                    <div class="w-full" *ngIf="opinions.value.length == 0">
                        <p class="text-sm text-gray-600">No hay opiniones</p>
                    </div> -->
                </div>
            </div>

        </div>



    </div>


    <div class="my-2 mx-4 pb-2 md:pt-0 md:pb-2 ">

    </div>

</div>
